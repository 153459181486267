<template>
  <div>
    <b-form-row v-for="(field, fIndex) in fields" :key="fIndex">
      <b-col>
        <b-form-group>
          <div class="floating-input-field">
            <datepicker
              v-if="field.fieldType == 'Date'"
              :id="`${field.name}-${fIndex}`"
              :bootstrapStyling="true"
              :typeable="true"
              :wrapperClass="{
                'is-value-exist': field.fieldValue,
                'is-invalid': !field.fieldValue && formSubmitted,
              }"
              v-model="field.fieldValue"
              format="MM-dd-yyyy"
            ></datepicker>
            <input
              v-else-if="field.fieldType == 'Amount'"
              type="text"
              :class="{
                'is-invalid': !field.fieldValue && formSubmitted,
              }"
              :id="`${field.name}-${fIndex}`"
              v-model="field.fieldValue"
              :placeholder="field.name"
              v-amount
              required
              class="form-control"
            />
            <input
              v-else
              :type="field.fieldType.toLowerCase()"
              :class="{
                'is-invalid': !field.fieldValue && formSubmitted,
              }"
              :id="`${field.name}-${fIndex}`"
              v-model="field.fieldValue"
              :placeholder="field.name"
              :autocomplete="
                field.fieldType.toLowerCase() == 'password'
                  ? 'new-password'
                  : 'off'
              "
              required
              class="form-control"
            />
            <label :for="`${field.name}-${fIndex}`">{{ field.name }}</label>
            <b-form-invalid-feedback
              class="d-block"
              v-if="!field.fieldValue && formSubmitted"
              >{{ field.name }} required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
export default {
  name: 'LinkAccountFormFields',
  props: ['fields', 'formSubmitted'],
  components: {
    Datepicker
  }
}
</script>
